import React from "react";

import { gridContainerStyles } from "@df/multichannel-app/packages/shared-web/styles/grid";

import SiteTitle from "./SiteTitle";

const SiteHeader = () => (
  <header
    role="banner"
    style={{
      paddingTop: "1.5rem",
      paddingBottom: "1.5rem",
    }}
  >
    <div
      style={{
        ...gridContainerStyles,
      }}
    >
      <SiteTitle />
    </div>
  </header>
);

export default SiteHeader;
