import { graphql, Link, useStaticQuery } from "gatsby";
import React from "react";

import { Consumer as LayoutContextConsumer } from "@df/multichannel-app/packages/shared-web/contexts/Layout";

import logoStacked from "../content/assets/logo-stacked.svg";
import logo from "../content/assets/logo.svg";

export interface SiteTitleStaticQueryInterface {
  site: {
    siteMetadata: {
      title: string;
    };
  };
}

const SiteTitle = () => {
  const { site }: SiteTitleStaticQueryInterface = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
          }
        }
      }
    `
  );

  const siteLogo = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <img
        src={logo}
        alt={site.siteMetadata.title}
        style={{
          height: "60px",
          width: "auto",
          display: "block",
          marginBottom: 0,
        }}
      />
    </div>
  );

  const siteLogoStacked = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <img
        src={logoStacked}
        alt={site.siteMetadata.title}
        style={{
          height: "120px",
          width: "auto",
          display: "block",
          marginBottom: 0,
        }}
      />
    </div>
  );

  return (
    <LayoutContextConsumer>
      {({ breakpoint }) => (
        <Link to="/" title={site.siteMetadata.title}>
          {breakpoint === "sm" && siteLogoStacked}
          {breakpoint !== "sm" && siteLogo}
        </Link>
      )}
    </LayoutContextConsumer>
  );
};

export default SiteTitle;
