import { graphql, StaticQuery } from "gatsby";
import React from "react";

import Link from "@df/multichannel-app/packages/shared-web/components/Link";
import { gridContainerStyles } from "@df/multichannel-app/packages/shared-web/styles/grid";
import { colors } from "@df/multichannel-app/packages/shared/styles/colors";

const SiteFooter = () => {
  const date = new Date();

  return (
    <StaticQuery
      query={siteMetadataQuery}
      render={(data) => (
        <footer
          role="contentinfo"
          style={{
            fontSize: "12px",
            marginTop: "1rem",
            marginBottom: "1rem",
            color: colors.grey4,
            textAlign: "center",
            position: "absolute",
            right: 0,
            bottom: 0,
            left: 0,
          }}
        >
          <div style={{ ...gridContainerStyles, flexGrow: 1 }}>
            Copyright &copy; {data.site.siteMetadata.businessName} 2018-
            {date.getFullYear()}.{" "}
            <Link to="/legals" title="Legals" type="primary">
              The legal stuff
            </Link>
            . Built with{" "}
            <Link
              to="https://www.gatsbyjs.org"
              title="GatsbyJS"
              target="_blank"
              type="primary"
            >
              Gatsby
            </Link>
            .
          </div>
        </footer>
      )}
    />
  );
};

export default SiteFooter;

const siteMetadataQuery = graphql`
  query DefaultSEOQuery {
    site {
      siteMetadata {
        businessName
      }
    }
  }
`;
