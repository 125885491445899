import { MDXProvider } from "@mdx-js/react";
import * as React from "react";

import { Consumer as LayoutContextConsumer } from "@df/multichannel-app/packages/shared-web/contexts/Layout";
import { colors } from "@df/multichannel-app/packages/shared/styles/colors";
import MdxCodeComponent from "@df/multichannel-app/packages/web/src/components/MdxCodeComponent";
import MdxTableComponent from "@df/multichannel-app/packages/web/src/components/MdxTableComponent";

import SiteFooter from "../components/SiteFooter";
import SiteHeader from "../components/SiteHeader";

export interface SiteLayoutProps {
  location: {
    pathname: string;
  };
}

class SiteLayout extends React.Component<SiteLayoutProps> {
  render() {
    const { children } = this.props;

    return (
      <LayoutContextConsumer>
        {({ breakpoint }) => (
          <div
            style={{
              minHeight: "100vh",
              position: "relative",
              paddingBottom: breakpoint === "sm" ? "3.5rem" : "2.5rem",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              style={{
                border: `1px solid ${colors.black}`,
                margin: "1rem",
                maxWidth: "620px",
              }}
            >
              <SiteHeader />

              <main role="main">
                <MDXProvider
                  components={{
                    code: MdxCodeComponent,
                    table: MdxTableComponent,
                  }}
                >
                  {children}
                </MDXProvider>
              </main>

              <SiteFooter />
            </div>
          </div>
        )}
      </LayoutContextConsumer>
    );
  }
}

export default SiteLayout;
